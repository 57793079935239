import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLoaderData } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import NavL from "./NavL";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
    // ["link", "image"],
    ["link"],
    // ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  // "image",
];

const Update = () => {
  const { isDay } = useSelector((state) => state.dayNight);
  const singleBlog = useLoaderData();
  const [blog, setBlog] = useState(singleBlog);
  const [name, setName] = useState(blog?.name);
  const [summary, setSummary] = useState(blog?.summary);
  const [success, setSuccess] = useState(false);
  const [content, setContent] = useState(blog?.content);

  useEffect(() => {
    document.title = `Update-${singleBlog?.name}`;
  }, []);
  const handleUpdate = (e) => {
    e.preventDefault();
    const date = new Date();
    const todaysDate = date.toLocaleDateString();
    const data = {
      name: name,
      summary: summary,
      content: content,
      updatedAt: todaysDate,
    };
    setSuccess(false);

    // fetch(`https://ser-gules.vercel.app/blogs/${singleBlog?._id}`, {
    fetch(`https://ser-gules.vercel.app/blogs/${singleBlog?._id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data?.acknowledged) {
          setSuccess(true);
        }
      });
  };

  //
  //server-88qwureus-mdhosen21018-gmailcom.vercel.app/

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
    borderColor: "000",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
    borderColor: "fff",
  };

  return (
    <>
      <Menubar />
      <div
        className="add_create"
        style={isDay ? objectStyle : objectStyleValid}
      >
        <Container>
          <h3>Update blog:</h3>
          <form onSubmit={handleUpdate}>
            <input
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
              defaultValue={singleBlog.name}
            />
            <input
              type="text"
              name="summary"
              onChange={(e) => setSummary(e.target.value)}
              defaultValue={singleBlog.summary}
            />

            <ReactQuill
              className="blog__content"
              formats={formats}
              modules={modules}
              defaultValue={singleBlog.content}
              onChange={(newValue) => setContent(newValue)}
            />

            <button type="submit" className="btn_update my-3">
              Update Blog
            </button>
          </form>
        </Container>
        {success && (
          <div className="custom_modal succes_custom_modal update_modal">
            <div className="modal_content success_modal_cont">
              <MdOutlineDone />
              <h3>Updated successfully!</h3>

              <div className="modal_btn">
                {/* <span className="cancel_btn" onClick={closeModal}>
                Cancel
              </span> */}
                <Link
                  className="delete_btn success_update_btn"
                  to={`/blog/single/${singleBlog._id}`}
                >
                  View
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Update;
