import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/UserContexts";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Container } from "react-bootstrap";
import { MdOutlineDone } from "react-icons/md";

import NavL from "./NavL";
import { Link } from "react-router-dom";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
    ["link"],
    // ["link", "image"],
    // ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  // "image",
];
const AddBlog = () => {
  const { isDay } = useSelector((state) => state.dayNight);

  const { user, logOutUser } = useContext(AuthContext);

  // const [blog, setBlog] = useState({});
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = "Create new blog";
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const acknowledged;
    const date = new Date();
    const todaysDate = date.toLocaleDateString();
    setSuccess(false);
    const data = {
      name: name,
      summary: summary,
      content: content,
      createdAt: todaysDate,
    };
    //https://ser-gules.vercel.app/
    console.log(data);
    // fetch("https://ser-gules.vercel.app/blogs", {
    fetch("https://ser-gules.vercel.app/blogs", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data?.acknowledged) {
          setSuccess(true);
        }
      });
  };
  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
    borderColor: "000",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
    borderColor: "fff",
  };

  return (
    <>
      <Menubar />
      <div
        className="add_create"
        style={isDay ? objectStyle : objectStyleValid}
      >
        <Container>
          {/* <NavL /> */}

          <div>
            <h3> Create new blog:</h3>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Title"
                required
                onChange={(e) => setName(e.target.value)}
              />

              <input
                type="text"
                name="summary"
                placeholder="Summary"
                required
                onChange={(e) => setSummary(e.target.value)}
              />

              <ReactQuill
                className="blog__content"
                formats={formats}
                modules={modules}
                onChange={(newValue) => setContent(newValue)}
                required
                style={isDay ? objectStyle : objectStyleValid}
              />

              <button type="submit" className="btn_create my-3">
                Create
              </button>
            </form>
          </div>
        </Container>
        {success && (
          <div className="custom_modal succes_custom_modal">
            <div className="modal_content success_modal_cont">
              <MdOutlineDone />
              <h3>New blog created successfully!</h3>

              <div className="modal_btn">
                {/* <span className="cancel_btn" onClick={closeModal}>
                Cancel
              </span> */}
                <Link className="delete_btn success_modal_btn" to="/blog">
                  See Blog
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddBlog;
