import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDay: false,
};

const dayNightSlice = createSlice({
  name: "dayNight",
  initialState,
  reducers: {
    filterDay: (state, action) => {
      console.log(action);
      state.isDay = action.payload;
    },
  },
});

export default dayNightSlice.reducer;
export const { filterDay } = dayNightSlice.actions;
