import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BsFillMoonFill } from "react-icons/bs";
import { ImSwitch } from "react-icons/im";
import { AiOutlineLogin } from "react-icons/ai";

import Container from "react-bootstrap/Container";
import { AuthContext } from "../contexts/UserContexts";
const NavL = () => {
  const { user, logOutUser } = useContext(AuthContext);

  return (
    <Container>
      <div className="menubar navL">
        <div className="log">
          {user?.uid && <spna className="online"></spna>}
          <Link to="/">Agitoz</Link>
        </div>
        <div className="item">
          <Link to="/about">About</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/blog">Blog</Link>
          {/* <Link href="tel:8665562570">Contact</Link> */}
          <a href = "mailto: abc@example.com">Contact</a>
          {/* <BsFillMoonFill /> */}
          {user?.uid ? (
            <spna onClick={logOutUser} className="logout" title="Log Out">
              <ImSwitch />
            </spna>
          ) : (
            <Link to="/signin" className="login" title="Log In">
              <AiOutlineLogin />
            </Link>
          )}
        </div>
      </div>
    </Container>
  );
};

export default NavL;
