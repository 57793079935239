import React from "react";
import { Container } from "react-bootstrap";
import Navbar from "./Navbar";
import NavL from "./NavL";
import { useSelector } from "react-redux";

const About = () => {
  const { isDay } = useSelector((state) => state.dayNight);
  console.log(isDay);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };

  return (
    <div style={isDay ? objectStyle : objectStyleValid}>
      <Container>
        {/* <NavL /> */}

        <div className="about_me">
          <h1>About me</h1>
          <p>
            I am London-based aspiring software-engineer, I'm currently
            harnessing my skills to become a full-time software engineer. Where
            I can fully commit my time to becoming a valuable asset to a team of
            similarly aspiring people. I spend most of my time working on code
            or working out. Feel free to have a look at some projects I'm
            currently working on. :)
          </p>
        </div>
      </Container>
    </div>
  );
};

export default About;
