import React, { useEffect } from "react";
import Projects from "../components/Projects";
import Menubar from "../components/Menubar";
import { useSelector } from "react-redux";

const ProjectsPage = () => {
  useEffect(() => {
    document.title = "Projects";
  }, []);
  const { isDay } = useSelector((state) => state.dayNight);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };
  return (
    <div className="projects" style={isDay ? objectStyle : objectStyleValid}>
      <Menubar />
      <Projects />
    </div>
  );
};

export default ProjectsPage;
