import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Container } from "react-bootstrap";
import NavL from "./NavL";
import { Link, useLoaderData } from "react-router-dom";
import Blog from "./Blog";
import { FcEditImage } from "react-icons/fc";
import { BsPencilSquare } from "react-icons/bs";
import { ImSpinner2 } from "react-icons/im";
import Spinner from "react-bootstrap/Spinner";

import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCrossCircled } from "react-icons/rx";
import { AuthContext } from "../contexts/UserContexts";
import nothinImag from "../assets/nothing.jpg";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const Blogs = () => {
  const { isDay } = useSelector((state) => state.dayNight);
  const { user, logOutUser } = useContext(AuthContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [newBlogs, setNewBlogs] = useState([]);
  const [deleteItem, setDeleteItem] = useState("");
  console.log(isDay);

  const openModal = (blog) => {
    setDeleteModal(true);
    setDeleteItem(blog);
  };

  const closeModal = () => {
    setDeleteModal(false);
  };
  useEffect(() => {
    document.title = "My Blogs";
  }, []);

  const blogs = useLoaderData();
  console.log(loading);

  // blogs-https://ser-gules.vercel.app/

  useEffect(() => {
    // const uri = "https://ser-gules.vercel.app/blogs";
    const uri = "https://ser-gules.vercel.app/blogs";
    setLoading(true);

    fetch(uri)
      .then((res) => res.json())
      .then((data) => {
        setLoading(true);
        setNewBlogs(data);
        setLoading(false);
      });
  }, []);

  // console.log(newBlogs);
  const reverseDispaly = [...newBlogs].reverse();

  // console.log(reverseDispaly);
  const [displayBlogs, setDisplayBlogs] = useState(reverseDispaly);

  // console.log(displayBlogs);

  let content;

  if (!reverseDispaly) {
    content = <div>No blog posted yet!</div>;
  } else if (reverseDispaly?.length > 0) {
    content = reverseDispaly;
  }
  // https://ser-gules.vercel.app/
  // console.log(content);
  const handleDelete = () => {
    setDeleteModal(false);
    fetch(`https://ser-gules.vercel.app/blogs/${deleteItem._id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.deletedCount > 0) {
          const remainingBlogs = reverseDispaly.filter(
            (blg) => blg._id !== deleteItem._id
          );
          setNewBlogs(remainingBlogs);
        }
      });
  };
  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
    borderColor: "000",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
    borderColor: "fff",
  };
  return (
    <>
      <Menubar />

      <div className="blogs" style={isDay ? objectStyle : objectStyleValid}>
        <Container>
          <div className="create_blog">
            {user?.uid && (
              <>
                <h4>Total blog: {reverseDispaly?.length}</h4>
                <Link to="/blog/create" className="create_blog--btn">
                  Create New Blog
                </Link>
              </>
            )}
          </div>

          {loading ? (
            // <>Loading</>
            <Spinner animation="border" className="my-5" />
          ) : (
            <>
              {reverseDispaly?.length === 0 ? (
                <h2>No blog posted yet</h2>
              ) : (
                <>
                  {content?.map((blog) => (
                    <div
                      className="blog_item"
                      key={blog._id}
                      blog={blog}
                      style={isDay ? objectStyle : objectStyleValid}
                    >
                      <Link
                        to={`/blog/single/${blog?._id}`}
                        style={isDay ? objectStyle : objectStyleValid}
                      >
                        <div className="links">
                          <h4>{blog?.name}</h4>
                        </div>
                      </Link>
                      <div className="actions">
                        <div className="editable">
                          {user?.uid && (
                            <>
                              <Link
                                to={`/blog/edit/${blog?._id}`}
                                style={isDay ? objectStyle : objectStyleValid}
                              >
                                <BsPencilSquare />
                              </Link>
                              <span
                                onClick={() => openModal(blog)}
                                className="delete__btn"
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </>
                          )}
                        </div>
                        <p>{blog?.createdAt}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Container>

        {deleteModal && (
          <div className="custom_modal">
            <div className="modal_content">
              <div className="delete_icon">
                <RxCrossCircled />
              </div>
              <h3>Are you sure?</h3>
              <p>
                Do you really want to delete this records?This process can not
                be undone!
              </p>
              <div className="modal_btn">
                <span className="cancel_btn" onClick={closeModal}>
                  Cancel
                </span>
                <span className="delete_btn" onClick={handleDelete}>
                  Delete
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
