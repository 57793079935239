import React, { useEffect } from "react";
import About from "../components/About";
import Menubar from "../components/Menubar";
import { useSelector } from "react-redux";
const AboutPage = () => {
  useEffect(() => {
    document.title = "About";
  }, []);
  const { isDay } = useSelector((state) => state.dayNight);
  console.log(isDay);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };
  return (
    <div style={isDay ? objectStyle : objectStyleValid} className="about">
      <Menubar />
      <About />
    </div>
  );
};

export default AboutPage;
