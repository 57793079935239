import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { FcEditImage } from "react-icons/fc";
import { AiFillDelete } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import Menubar from "./Menubar";
import Typewriter from "typewriter-effect";
import { useSelector } from "react-redux";

const Home = () => {
  const { isDay } = useSelector((state) => state.dayNight);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };
  return (
    <>
      {/* <Menubar /> */}
      <div className="home" style={isDay ? objectStyle : objectStyleValid}>
        <h1>Hello, I'm Agit</h1>
        <div>
          <IoIosArrowForward />
          <IoIosArrowForward />
          <span>Hello World!</span>
        </div>
        <div>
          <IoIosArrowForward />
          <IoIosArrowForward />
          <Typewriter
            options={{
              strings: ["An aspiring software engineer"],
              autoStart: true,
              loop: true,
            }}
          />
          {/* <span>software engineer and CompSci grad_</span> */}
        </div>
        <div className="social">
          <Link
            to="https://www.linkedin.com/in/agit-ozcelik-a65152208/"
            target="_blank"
          >
            <AiFillLinkedin />
          </Link>
          <Link to="https://github.com/agit142" target="_blank">
            <FaGithub />
          </Link>
          <Link to="https://twitter.com/Agit_2014" target="_blank">
            <BsTwitter />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
