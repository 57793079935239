// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBC5y4LyeXsIaySg7sj_GC7NsYQs_rjC60",
  authDomain: "agit-f4a75.firebaseapp.com",
  projectId: "agit-f4a75",
  storageBucket: "agit-f4a75.appspot.com",
  messagingSenderId: "55144914950",
  appId: "1:55144914950:web:9b91d56e5a1af465cc2387"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
