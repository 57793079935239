import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/UserContexts";
import { ImSwitch } from "react-icons/im";
import { AiOutlineLogin } from "react-icons/ai";
import { BsMoonFill } from "react-icons/bs";
import { BsSunFill } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { filterDay } from "../features/dayNight/dayNightSlice";

const Menubar = () => {
  const { isDay } = useSelector((state) => state.dayNight);
  console.log(isDay);

  const { user, logOutUser } = useContext(AuthContext);
  const hamburg = document.querySelector(".hamburg");
  const headerUl = document.querySelector(".nav_menu");
  const [toggled, setToggled] = useState(false);
  const [day, setDay] = useState(isDay);

  const hamBurg = () => {
    if (toggled) {
      setToggled(false);
    } else {
      setToggled(true);
    }
  };

  const sunMood = () => {
    if (day) {
      setDay(false);
    } else {
      setDay(true);
    }
  };
  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };

  const dispatch = useDispatch();
  dispatch(filterDay(day));

  return (
    <header style={isDay ? objectStyle : objectStyleValid}>
      <a href="" className="logo">
        <Link to="/" style={isDay ? objectStyle : objectStyleValid}>
          Agit
        </Link>
        {user?.uid && <spna className="online"></spna>}
      </a>
      <ul className={toggled ? "nav_menu active" : "nav_menu"}>
        <li className="nav_link" onClick={hamBurg}>
          <Link to="/about" style={isDay ? objectStyle : objectStyleValid}>
            About
          </Link>
        </li>
        <li className="nav_link" onClick={hamBurg}>
          <Link to="/projects" style={isDay ? objectStyle : objectStyleValid}>
            Projects
          </Link>
        </li>
        <li className="nav_link" onClick={hamBurg}>
          <Link to="/blog" style={isDay ? objectStyle : objectStyleValid}>
            Blogs
          </Link>
        </li>
        <li className="nav_link" onClick={hamBurg}>
          <a
            href="mailto:agit_2014@hotmail.co.uk"
            style={isDay ? objectStyle : objectStyleValid}
          >
            Contact
          </a>
        </li>
        {/* <li onClick={sunMood}>{day ?  : <FiMoon />}</li> */}
        {isDay ? (
          <li
            className="day"
            onClick={() => {
              setDay(false);
            }}
          >
            <BsMoonFill />
          </li>
        ) : (
          <li
            className="night"
            onClick={() => {
              setDay(true);
            }}
          >
            <BsSunFill />
          </li>
        )}

        <li>
          {user?.uid ? (
            <spna onClick={logOutUser} className="logout" title="Log Out">
              <ImSwitch />
            </spna>
          ) : (
            <Link to="/signin" className="login" title="Log In">
              <AiOutlineLogin />
            </Link>
          )}
        </li>
      </ul>

      <div className={toggled ? "hamburg active" : "hamburg"} onClick={hamBurg}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
};

export default Menubar;
