import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import { Container, Row, Col } from "react-bootstrap";
import { AuthContext } from "../contexts/UserContexts";
import { Link } from "react-router-dom";
import NavL from "../components/NavL";
import Menubar from "../components/Menubar";
import { useSelector } from "react-redux";

const HomePage = () => {
  const { isDay } = useSelector((state) => state.dayNight);
  console.log(isDay);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };
  const { user, logOutUser } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Agitoz";
  }, []);
  return (
    <div style={isDay ? objectStyle : objectStyleValid} className="homepage">
      <Menubar />
      <div>
        <Container>
          <div className="home_content">
            {/* <NavL /> */}
            <Home />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
