import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import Navbar from "./Navbar";
import { Container } from "react-bootstrap";
import { FcEditImage } from "react-icons/fc";
import { RiDeleteBin6Line } from "react-icons/ri";
import NavL from "./NavL";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const SingleBLog = () => {
  const { isDay } = useSelector((state) => state.dayNight);

  const singleBlog = useLoaderData();
  const content = singleBlog.content;

  useEffect(() => {
    document.title = `Blog-${singleBlog?.name}`;
  }, []);

  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
  };

  return (
    <>
      <Menubar />
      <div
        className="single_blog"
        style={isDay ? objectStyle : objectStyleValid}
      >
        <Container>
          {/* <NavL></NavL> */}
          <div className="blog_details">
            <h1>{singleBlog?.name}</h1>
            <p>{singleBlog?.createdAt}</p>

            <p>{singleBlog?.summary}</p>
            <p>{singleBlog?.details}</p>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="content"
              style={isDay ? objectStyle : objectStyleValid}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default SingleBLog;
