import React from "react";
import { Container } from "react-bootstrap";
import NavL from "./NavL";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const Projects = () => {
  return (
    <>
      {/* <Menubar /> */}
      <div>
        <Container>
          <div className="my-5">No project.</div>
        </Container>
      </div>
    </>
  );
};

export default Projects;
