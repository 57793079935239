import React, { useContext, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import app from "../firebase/firebase.init";
import { AuthContext } from "../contexts/UserContexts";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { HiMail } from "react-icons/hi";
import { FiLock } from "react-icons/fi";
import Menubar from "./Menubar";
import { useSelector } from "react-redux";

const auth = getAuth(app);

const Login = () => {
  const { isDay } = useSelector((state) => state.dayNight);

  const { loginUser } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [userError, setUserError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserError(false);
    loginUser(email, password)
      .then((result) => {
        const user = result.user;
        navigate(from, { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setUserError(true);
      });
  };
  const objectStyle = {
    color: "#000",
    backgroundColor: "RGB(159, 189, 130)",
    borderColor: "000",
  };
  const objectStyleValid = {
    color: "#fff",
    backgroundColor: "RGB(49, 54, 44)",
    borderColor: "fff",
  };

  return (
    <div className="login_page" style={isDay ? objectStyle : objectStyleValid}>
      <Menubar />
      <div style={isDay ? objectStyle : objectStyleValid}>
        <Container>
          <div className="log_in">
            <h3>Please sign in:</h3>
            <form onSubmit={handleSubmit}>
              <div className="field">
                <HiMail />
                <input
                  type="email"
                  name="email"
                  id=""
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="field">
                <FiLock />
                <input
                  type="password"
                  name="email"
                  id=""
                  onChange={(e) => setPass(e.target.value)}
                  placeholder="Password"
                  required
                />
              </div>
              <button type="submit">Sign In</button>
            </form>

            {userError && <p>Enter correct email and password.</p>}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Login;
