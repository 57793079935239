import React, { useContext } from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
import PostPage from "./pages/PostPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/Home";
import AddBlog from "./components/AddBlog";
import Update from "./components/Update";
import CreateBlog from "./components/CreateBlog";
import HomePage from "./pages/HomePage";
import Blogs from "./components/Blogs";
import Projects from "./components/Projects";
import About from "./components/About";
import Login from "./components/Login";
import PrivateRoute from "./route/PrivateRoute";
import { AuthContext } from "./contexts/UserContexts";
import { UserContext } from "./UserContext";
import SingleBLog from "./components/SingleBLog";
import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";
import Menubar from "./components/Menubar";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "blog/create",
      element: (
        <PrivateRoute>
          {" "}
          <AddBlog />
        </PrivateRoute>
      ),
    },
    {
      path: "signin",
      element: <Login />,
    },
    {
      path: "blog",
      element: <Blogs />,
      // loader: () => fetch("https://ser-gules.vercel.app/blogs"),
      loader: () => fetch("https://ser-gules.vercel.app/blogs"),
    },
    {
      path: "projects",
      element: <ProjectsPage />,
    },
    {
      path: "about",
      element: <AboutPage />,
    },
    {
      path: "blog/edit/:id",
      element: (
        <PrivateRoute>
          <Update />
        </PrivateRoute>
      ),
      loader: ({ params }) =>
        // fetch(`https://ser-gules.vercel.app/blogs/${params.id}`),
        fetch(`https://ser-gules.vercel.app/blogs/${params.id}`),
    },
    {
      path: "blog/single/:id",
      element: <SingleBLog />,
      loader: ({ params }) =>
        // fetch(`https://ser-gules.vercel.app/blogs/${params.id}`),
        fetch(`https://ser-gules.vercel.app/blogs/${params.id}`),
    },
  ]);
  return (
    <>
      <div className="App">
        <>
          {/* <Menubar /> */}
          <RouterProvider router={router} />
        </>
      </div>
    </>
  );
}

export default App;
